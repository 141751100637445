export default function FlyoverCarousel(el, {
	containerClass,
	slidesWrapperClass,
	slideClass,
	actions,
	events,
}) {
	const slidesWrapper = el.querySelector(`.${slidesWrapperClass}`);
	const slides = slidesWrapper.querySelectorAll(`.${slideClass}`);
	const prev = el.querySelector(`.${containerClass}__nav-prev`);
	const next = el.querySelector(`.${containerClass}__nav-next`);

	const videos = document.querySelectorAll('.video');
	const progressBarWrappers = document.querySelectorAll('.progress');
	const progressBars = document.querySelectorAll('.progress-bar');
	const progressNodes = document.querySelectorAll('.progress-node');
	const pulseNodes = document.querySelectorAll('.pulse-node');

	const slidesWrapperHeight = () => {
		let maxHeight = 0;
		slides.forEach((slide) => {
			const slideHeight = slide.offsetHeight;
			if (slideHeight > maxHeight) {
				maxHeight = slideHeight;
			}
			slide.style.position = 'absolute';
		});
		slidesWrapper.style.height = `${maxHeight}px`;
	};
	slidesWrapperHeight();
	window.addEventListener('resize', () => {
		slides.forEach(slide => slide.style.position = 'relative');
		slidesWrapperHeight();
	});

	const handleNavButtons = (slideIndex, event) => {
		if (slideIndex === 1) {
			prev.disabled = true;
		} else if (slideIndex === slides.length) {
			next.disabled = true;
		} else {
			prev.disabled = false;
			next.disabled = false;
		}
		event.target.disabled = true;
		setTimeout(() => {
			event.target.disabled = false;
		}, 500);
	};

	const handlePulseNodes = (activeIndex) => {
		progressBarWrappers.forEach((bar, index) => {
			index === activeIndex - 1 ? bar.classList.add('active') : bar.classList.remove('active');
		});

		if (activeIndex === 0) {
			progressBarWrappers[0].classList.add('active');
			pulseNodes[0].classList.add('active');
		} else {
			pulseNodes[0].classList.remove('active');
		}
	};

	videos.forEach((video, index) => {
		video.addEventListener('timeupdate', function() {
			const percentage = (video.currentTime / video.duration) * 100;
			progressBars[index].style.width = `${percentage}%`;
			progressNodes[index].style.left = `${percentage}%`;

			if (percentage === 100 && index < videos.length - 1) {
				pulseNodes[index + 1].classList.add('active');
			} else if (percentage !== 100 && index !== 0) {
				pulseNodes[index].classList.remove('active');
			}
		});
	});
	
	next.addEventListener('click', (e) => {
		const currentSlide = slidesWrapper.querySelector(`.${slideClass}--active`);
		const currentIndex = currentSlide.dataset.slideIndex;
		const currentVideo = currentSlide.querySelector('video');

		const nextSlide = slidesWrapper.querySelector(`[data-slide-index="${parseInt(currentIndex) + 1}"]`);
		const nextVideo = nextSlide.querySelector('video');
		
		currentVideo.currentTime = currentVideo.duration;
		currentVideo.pause();
		currentSlide.classList.remove(`${slideClass}--active`);

		nextSlide.classList.add(`${slideClass}--active`);
		videos.forEach((video) => {
			video.removeAttribute('playsinline');
			video.removeAttribute('muted');
		});
		nextVideo.setAttribute('playsinline', true);
		nextVideo.setAttribute('muted', true);
		nextVideo.play();

		handleNavButtons(parseInt(currentIndex) + 1, e);
		handlePulseNodes(parseInt(currentIndex)), e;
	});

	prev.addEventListener('click', (e) => {
		const currentSlide = slidesWrapper.querySelector(`.${slideClass}--active`);
		const currentIndex = currentSlide.dataset.slideIndex;
		const currentVideo = currentSlide.querySelector('video');

		const prevSlide = slidesWrapper.querySelector(`[data-slide-index="${parseInt(currentIndex) - 1}"]`);
		const prevVideo = prevSlide.querySelector('video');
		
		currentVideo.currentTime = 0;
		currentVideo.pause();
		currentSlide.classList.remove(`${slideClass}--active`);

		prevSlide.classList.add(`${slideClass}--active`);
		prevVideo.currentTime = 0;
		videos.forEach((video) => {
			video.removeAttribute('playsinline');
			video.removeAttribute('muted');
		});
		prevVideo.setAttribute('playsinline', true);
		prevVideo.setAttribute('muted', true);
		prevVideo.play();

		handleNavButtons(parseInt(currentIndex) - 1, e);
		handlePulseNodes(parseInt(currentIndex) - 2, e);
	});

	events.on(actions.flyoverToggle, ({ detail }) => {
		const activeVideo = slidesWrapper.querySelector(`.${slideClass}--active`).querySelector('video');
		if (detail.action === 'pause') {
			setTimeout(() => {
				activeVideo.pause();
			}, 100);
		} else {
			activeVideo.duration.toFixed(2) !== activeVideo.currentTime.toFixed(2) && activeVideo.play();
		}
	});
}