// Framework
import pop from './components';

// Components
import Button from './components/button';
import CtaTab from './components/cta-tab';
import ExpandingDetails from './components/expanding-details';
import Form from './components/form';
import FormField from './components/form-field';
import FyloverCarousel from './components/flyover-carousel';
import Header from './components/header';
import MediaCarousel from './components/media-carousel';
import Modal from './components/modal';
import QuickFacts from './components/quick-facts';
import TestimonialCarousel from './components/testimonial-carousel';
import WatchVideoBtn from './components/watch-video-btn';

// Define map of component handles to component classes
const classMap = {
    button: Button,
	'cta-tab': CtaTab,
	'expanding-details': ExpandingDetails,
	form: Form,
	'form-field': FormField,
	'flyover-carousel': FyloverCarousel,
	header: Header,
	'media-carousel': MediaCarousel,
	modal: Modal,
    'quick-facts': QuickFacts,
	'testimonial-carousel': TestimonialCarousel,
	'watch-video-btn': WatchVideoBtn,
};

// Define all actions/commands that components pub/sub
const actions = {
    // Action events
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    openModal: 'open-modal',
    closeModal: 'close-modal',
    loadModal: 'load-modal',
    showFieldError: 'show-field-error',
	flyoverToggle: 'flyover-toggle',
};

// Event handler functions
function handleDOMConentLoaded() {
    function cb({ events }) {
        function handleBodyClick(e) {
            const link = e.target.closest('a');

            if (!link) return;

            // Anchors
            if (link.matches('[href="#"]')) {
                e.preventDefault();

                const target = document.querySelector(link.href);

                if (target) {
                    target.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }

        document.body.addEventListener('click', handleBodyClick);

        if (window.location.hash) {
            const target = document.querySelector(window.location.hash);

            if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }

        const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
		
		// Only play videos when they are in view for scroll performance
        if (!prefersReducedMotion) {
			const videoObserver = new IntersectionObserver(entries => {
				entries.forEach(({ target, isIntersecting }) => {
					if (isIntersecting) {
						target.play();
					} else {
						target.pause();
					}
				});
			}, { threshold: 0.1 });
			Array.from(document.querySelectorAll('video'))
				.filter(v => v.hasAttribute('playsinline'))
				.forEach(v => { videoObserver.observe(v); });
		}

		if (prefersReducedMotion) {
			videos = document.querySelectorAll('video');
			videos.forEach(video => video.pause());
		}
    }

    pop({ classMap, actions, cb });
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
} else {
    handleDOMConentLoaded();
}
