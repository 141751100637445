export default function CtaTab(el, {
    activeClass,
}) {
    const els = document.querySelectorAll('.hero, .footer');
    const obCallback = entries => {
        el.classList.toggle(activeClass, entries.every(e => !e.isIntersecting));
    };
    const observer = new IntersectionObserver(obCallback);

    els.forEach(e => observer.observe(e));
}
